import React from "react";

const About = () => {
  return (
    <div>
      <section class="section is-large">
        <h1 class="title">About Me</h1>
        <h2 class="subtitle">
          Hello, my little <strong>grimlins</strong>! like the one you're
          currently as is.
        </h2>
      </section>
    </div>
  );
};

export default About;
